import React, {PureComponent} from "react";

import {IPageResponse, SortDirection} from "../common/interfaces/IPageResponse";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { ElectricityInvoicesService } from "components/ElectricityInvoices/ElectricityInvoices.service";
import { ElectricityInvoiceFilters, IElectricityInvoice, IElectricityInvoiceSortFields, IElectricityInvoicesState } from "components/ElectricityInvoices/models";
import { ElectricityInvoicesSearchBar } from "components/ElectricityInvoices/ElectricityInvoicesSearchBar";
import { ElectricityInvoicesTable } from "components/ElectricityInvoices/ElectricityInvoicesTable";
import PDFPreviewModal from "components/Modals/PDFPreviewModal/PDFPreviewModal";
import printJS from "print-js";
import { PdfPreview } from "components/ElectricityAgreements/models";

const searchApi = AwesomeDebouncePromise(
    request => ElectricityInvoicesService.findInvoices(request),
    300
);

export default class ElectrcityInvoices extends PureComponent<any,IElectricityInvoicesState> {
    constructor(props: any) {
        super(props);
        this.state = {
            electricityInvoices: null,
            pageRequest: {
                page: 0,
                size: 20,
                sortDirection: SortDirection.ASC,
                sortField: IElectricityInvoiceSortFields.ID,
                searchQuery: null
            },
            filters: { },
            isRequesting: false,
            isDownloading: false,
            isExporting: false
        };
        this.props.onLoadValidatePage();
    }

    getElectricityInvoices = () => {
        ElectricityInvoicesService.findInvoices(this.state.pageRequest).then((response: IPageResponse<IElectricityInvoice>) => {
            this.setState({
                electricityInvoices: response
            });
        });
    };

    searchElectricityInvoices = (request: any) => {
        this.setState({
            isRequesting: true
        });
        searchApi(request).then((response: IPageResponse<IElectricityInvoice>) => {
            this.setState({
                electricityInvoices: response, isRequesting: false
            });
        })
    };

    onPageChange = (targetPage: number) => {
        const page = targetPage - 1;
        this.setState({
            pageRequest: {
                ...this.state.pageRequest,
                page
            }
        }, () => this.searchElectricityInvoices({
            page,
            size: this.state.pageRequest.size,
            sortDirection: this.state.pageRequest.sortDirection,
            sortField: this.state.pageRequest.sortField,
            searchQuery: this.state.filters.search
        }));
    };

    handleSortClick = (sortField: IElectricityInvoiceSortFields) => {
        let sortDirection = SortDirection.ASC;
        if (this.state.pageRequest.sortField.match(sortField)) {
            sortDirection = this.state.pageRequest.sortDirection === SortDirection.DESC ? SortDirection.ASC : SortDirection.DESC;
        }
        this.setState({
            pageRequest: {
                ...this.state.pageRequest,
                sortDirection,
                sortField
            }
        }, () => this.onPageChange(1))
    };

    handleFilterChange = (change: ElectricityInvoiceFilters) => {
        this.setState({
            electricityInvoices: null,
            pageRequest: {
                page: 0,
                size: this.state.pageRequest.size,
                sortDirection: this.state.pageRequest.sortDirection,
                sortField: this.state.pageRequest.sortField
            },
            filters: {
                ...this.state.filters,
                ...change
            }
        }, () => {
            this.searchElectricityInvoices({
                page: 0,
                size: this.state.pageRequest.size,
                sortDirection: this.state.pageRequest.sortDirection,
                sortField: this.state.pageRequest.sortField,
                searchQuery: this.state.filters.search
            });
        });
    };

    downloadElectricityInvoiceFile = (pdfPreview: PdfPreview) : void => {
        this.setState({
            ...this.state,
            isDownloading: true
        });
        ElectricityInvoicesService.getInvoiceFileWithDownload(
            pdfPreview.agreementId, 
            pdfPreview.fileName
        ).finally(() => this.setState({
            ...this.state,
            isDownloading: false
        }));
    }

    getPdfPreview = (electricityInvoice: IElectricityInvoice) => {
        this.setState({
            ...this.state,
            pdfPreviewLoading: electricityInvoice.id
        })
        ElectricityInvoicesService.getInvoiceFile(electricityInvoice.id)
            .then((response) => this.setState({
                    ...this.state,
                    pdfPreview: {
                        agreementId: electricityInvoice.id,
                        objectUrl: response.data,
                        fileName: `${electricityInvoice.invoiceNumber}.pdf`
                    }
            })).finally(() => this.setState({
                ...this.state,
                pdfPreviewLoading: undefined
            }))
    }

    generateInvoicesReport = () => {
        this.setState({ isExporting: true });
        ElectricityInvoicesService.generateInvoicesReport()
            .finally(() => this.setState({ isExporting: false }));
    }

    initializeRequests(){
        this.getElectricityInvoices();
    }

    componentDidMount(): void {
        this.initializeRequests()
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col">
                      <ElectricityInvoicesSearchBar
                        onFilter={this.handleFilterChange} 
                        filters={this.state.filters}
                        generateInvoicesReport={this.generateInvoicesReport}
                        exporting={this.state.isExporting}
                      />
                      {this.state.electricityInvoices !== null ?
                        <ElectricityInvoicesTable 
                          onPageChange={this.onPageChange}
                          onSort={this.handleSortClick}
                          electricityInvoices={this.state.electricityInvoices}
                          sortDirection={this.state.pageRequest.sortDirection}
                          sortField={this.state.pageRequest.sortField}
                          getPdfPreview={this.getPdfPreview}
                          pdfPreviewLoading={this.state.pdfPreviewLoading}
                        /> :
                        null
                      }
                    </div>
                </div>
                <PDFPreviewModal
                    show={!!this.state.pdfPreview}
                    onHide={() => this.setState({
                        ...this.state,
                        pdfPreview: undefined
                    })}
                    onDownload={() => this.downloadElectricityInvoiceFile(this.state.pdfPreview!)}
                    isDownloading={this.state.isDownloading}
                    onPrint={() => printJS({printable: this.state.pdfPreview?.objectUrl!, type: "pdf"})}
                    preview={this.state.pdfPreview}
                />        
            </div>
        );
    }
}