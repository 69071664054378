import React, {PureComponent} from "react";
import {IPageResponse, SortDirection} from "../common/interfaces/IPageResponse";
import {
    ElectricityAgreementFilters,
    IElectricityAgreement,
    IElectricityAgreementSortFields,
    IElectricityAgreementsState,
    PdfPreview
} from "../components/ElectricityAgreements/models";
import {ElectricityAgreementsService} from "../components/ElectricityAgreements/ElectricityAgreements.service";
import { ElectricityAgreementsTable } from "components/ElectricityAgreements/ElectricityAgreementsTable";
import { ElectricityAgreementsSearchBar } from "components/ElectricityAgreements/ElectricityAgreementsSearchBar";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { ElectricityPackagesService } from "components/ElectricityPackages/ElectricityPackages.service";
import { CreateElectricityAgreementModal } from "components/ElectricityAgreements/modals/CreateElectricityAgreementModal";
import { ElectricityAgreementInvoiceModal } from "components/ElectricityInvoices/modals/ElectricityAgreementInvoiceModal";
import { ElectricityInvoicesService } from "components/ElectricityInvoices/ElectricityInvoices.service";
import moment from "moment";
import { UpdateElectrictyAgreementPackageModal } from "components/ElectricityAgreements/modals/UpdateElectrictyAgreementPackageModal";
import PDFPreviewModal from "components/Modals/PDFPreviewModal/PDFPreviewModal";
import printJS from "print-js";
import { ISimplifiedInvoice } from "components/ElectricityInvoices/models";
import { ElectricityProductionTablesService } from "components/ElectricityProductionTables/ElectricityProductionTables.service";
import { ISimplifiedProductionTable } from "components/ElectricityProductionTables/models";

const searchApi = AwesomeDebouncePromise(
    request => ElectricityAgreementsService.getElectricityAgreements(request),
    300
);

export default class ElectricityAgreements extends PureComponent<any,IElectricityAgreementsState> {
    constructor(props: any) {
        super(props);
        this.state = {
            electricityAgreements: null,
            pageRequest: {
                page: 0,
                size: 20,
                sortDirection: SortDirection.ASC,
                sortField: IElectricityAgreementSortFields.ID,
                searchQuery: null
            },
            filters: { },
            packages: [],
            isCreateAgreementModalVisible: false,
            isCreateInvoicesModalVisible: false,
            isUpdatePackageModalVisible: false,
            isInvoiceModalVisible: false,
            isOfferModal: false,
            activeAgreement: null,
            isRequesting: false,
            isExporting: false,
            isDownloading: false,
            isGenerating: false
        };
        this.props.onLoadValidatePage();
    }

    getElectricityAgreements = () => {
        ElectricityAgreementsService.getElectricityAgreements(this.state.pageRequest).then((response: IPageResponse<IElectricityAgreement>) => {
            this.setState({
                electricityAgreements: response
            });
        });
    };

    fetchInvoiceStatusesForAgreements(agreements: IElectricityAgreement[]) {
        const lastMonthWithLastDay = moment().subtract(1, 'months').endOf('month').format("yyyy-MM-DD");
        for (let agreement of agreements) {
            this.findInvoicesForAgreement(agreement.contractNumber).then((invoices: ISimplifiedInvoice[]) => {
                if (!this.state.electricityAgreements) {
                    return;
                }
                const hasLastMonthsInvoice = invoices.some((invoice) => invoice.dateIssued === lastMonthWithLastDay);
                this.setState({
                    electricityAgreements: {
                        ...this.state.electricityAgreements,
                        content: this.state.electricityAgreements.content.map(a => a.id === agreement.id ? ({...a, hasLastMonthsInvoice }) : a)
                    }
                })
            })
        }
    }

    downloadElectricityAgremeentFile = (pdfPreview: PdfPreview) : void => {
        this.setState({
            ...this.state,
            isDownloading: true
        });
        ElectricityAgreementsService.getElectricityAgreementFileWithDownload(
            pdfPreview.agreementId, 
            pdfPreview.fileName
        ).finally(() => this.setState({
            ...this.state,
            isDownloading: false
        }));
    }

    getPdfPreview = (electricityAgreement: IElectricityAgreement) => {
        this.setState({
            ...this.state,
            pdfPreviewLoading: electricityAgreement.id
        })
        ElectricityAgreementsService.getElectricityAgreementFile(electricityAgreement.id)
            .then((response) => this.setState({
                ...this.state,
                pdfPreview: {
                    agreementId: electricityAgreement.id,
                    objectUrl: response.data,
                    fileName: `${electricityAgreement.contractNumber}.pdf`
                }
        })).finally(() => this.setState({
            ...this.state,
            pdfPreviewLoading: undefined
        }))
    }

    getElectricityAgreementsReport = () => {
        this.setState({ isExporting: true });
        ElectricityAgreementsService.getElectricityAgreementReportExcel().finally(() => this.setState({ isExporting: false }));
    }

    searchElectricityAgreements = (request: any) => {
        this.setState({
            isRequesting: true
        });
        searchApi(request).then((response: IPageResponse<IElectricityAgreement>) => {
            this.setState({
                electricityAgreements: response, isRequesting: false
            });
        })
    };

    cancelElectricityAgreementOffer = (agreementId: number) => {
        ElectricityAgreementsService.cancelElectricityAgreement(agreementId)
        .then(() => {
            this.initializeRequests()
        });
    }

    createInvoiceForAgreement = (agreementId: number) => {
        return ElectricityInvoicesService.createInvoiceForAgreement(agreementId);
    }

    findInvoicesForAgreement = (contractNumber: string) => {
        return ElectricityInvoicesService.findInvoicesForAgreement(contractNumber);
    }

    onPageChange = (targetPage: number) => {
        this.setState({
            pageRequest: {
                ...this.state.pageRequest,
                page: targetPage - 1
            }
        }, () => this.searchElectricityAgreements({
                page: targetPage - 1,
                size: this.state.pageRequest.size,
                sortDirection: this.state.pageRequest.sortDirection,
                sortField: this.state.pageRequest.sortField,
                searchQuery: this.state.filters.search,
                status: this.state.filters.status,
                packageName: this.state.filters.packageName
            })
        );
    };

    handleSortClick = (sortField: IElectricityAgreementSortFields) => {
        let sortDirection = SortDirection.ASC;
        if (this.state.pageRequest.sortField.match(sortField)) {
            sortDirection = this.state.pageRequest.sortDirection === SortDirection.DESC ? SortDirection.ASC : SortDirection.DESC;
        }
        this.setState({
            pageRequest: {
                ...this.state.pageRequest,
                sortDirection,
                sortField
            },
        }, () => this.onPageChange(1))
    };

    handleFilterChange = (change: ElectricityAgreementFilters) => {
        this.setState({
            electricityAgreements: null,
            pageRequest: {
                page: 0,
                size: this.state.pageRequest.size,
                sortDirection: this.state.pageRequest.sortDirection,
                sortField: this.state.pageRequest.sortField
            },
            filters: {
                ...this.state.filters,
                ...change
            }
        }, () => {
            this.searchElectricityAgreements({
                page: 0,
                size: this.state.pageRequest.size,
                sortDirection: this.state.pageRequest.sortDirection,
                sortField: this.state.pageRequest.sortField,
                searchQuery: this.state.filters.search,
                status: this.state.filters.status,
                packageName: this.state.filters.packageName
        });
        });
    };

    getElectricityPackageNames = () => {
        ElectricityPackagesService.getElectricityPackageNames()
            .then(res => this.setState({ packages: res }))
    }

    onCreateAgreement = () => {
        this.setState({ isCreateAgreementModalVisible: true });
    }

    onCreateAgreementOffer = () => {
        this.setState({ isCreateAgreementModalVisible: true, isOfferModal: true });
    }

    onUpdateElectricityAgreementPackage = (agreement: IElectricityAgreement) => {
        this.setState({ 
            isUpdatePackageModalVisible: true,
            activeAgreement: agreement
        })
    }

    showInvoiceModalForAgreement = (agreement: IElectricityAgreement) => {
        this.setState({ 
            isInvoiceModalVisible: true,
            activeAgreement: agreement
        })
    }

    getInvoiceFile = (invoice: ISimplifiedInvoice) => {
        ElectricityInvoicesService.getInvoiceFileWithDownload(invoice.id, `${invoice.invoiceNumber}.pdf`);
    }

    getProductionTableFile = (productionTable: ISimplifiedProductionTable) => {
        ElectricityProductionTablesService.getProductionTableFile(productionTable.id);
    }

    initializeRequests(){
        this.getElectricityAgreements();
        this.getElectricityPackageNames();
    }

    componentDidMount(): void {
        this.initializeRequests()
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col">
                    <ElectricityAgreementsSearchBar
                        filters={this.state.filters}
                        packages={this.state.packages.map((p: string) => ({ value: p, label: p }))}
                        getElectricityAgreementsReport={this.getElectricityAgreementsReport}
                        onFilter={this.handleFilterChange}
                        createAgreement={this.onCreateAgreement}
                        createAgreementOffer={this.onCreateAgreementOffer}
                        exporting={this.state.isExporting}
                        generating={this.state.isGenerating}
                        isDisabled={!this.state.electricityAgreements?.content.length}
                    />
                            {this.state.electricityAgreements !== null ?
                            <ElectricityAgreementsTable
                                onPageChange={this.onPageChange}
                                onSort={this.handleSortClick}
                                sortDirection={this.state.pageRequest.sortDirection}
                                sortField={this.state.pageRequest.sortField}
                                electricityAgreements={this.state.electricityAgreements}
                                getPdfPreview={this.getPdfPreview}
                                pdfPreviewLoading={this.state.pdfPreviewLoading}
                                updateElectricityAgreementPackage={this.onUpdateElectricityAgreementPackage}
                                showInvoiceModalForAgreement={this.showInvoiceModalForAgreement}
                                cancelElectricityAgreementOffer={this.cancelElectricityAgreementOffer}
                            /> :
                            null
                        }
                    </div>
                </div>
                {(this.state.isInvoiceModalVisible && this.state.activeAgreement) && (
                    <ElectricityAgreementInvoiceModal 
                        handleModalHide={() => {
                            this.setState({
                                ...this.state,
                                isInvoiceModalVisible: false,
                                activeAgreement: null
                            })
                        }}  
                        activeAgreement={this.state.activeAgreement}
                        findInvoicesForAgreement={this.findInvoicesForAgreement}
                        createInvoiceForAgreement={this.createInvoiceForAgreement}
                        getInvoiceFile={this.getInvoiceFile}
                    />   
                )}
                {this.state.isCreateAgreementModalVisible ? <CreateElectricityAgreementModal
                    handleModalHide={() => {
                        this.setState({ isCreateAgreementModalVisible: false, isOfferModal: false });
                        this.getElectricityAgreements();
                    }}
                    isOffer={this.state.isOfferModal}
                    onSuccess={() => this.initializeRequests()}
                /> : null }
                {(this.state.isUpdatePackageModalVisible && this.state.activeAgreement) ? <UpdateElectrictyAgreementPackageModal 
                    handleModalHide={() => {
                        this.setState({ 
                            ...this.state,
                            isUpdatePackageModalVisible: false,
                            activeAgreement: null
                         });
                    }}
                    agreement={this.state.activeAgreement}
                    isJointInvoicePossible={this.state.activeAgreement.meteringPoints.every(mp => mp.hasMatchingGridOperator)}
                    onSuccess={() => this.initializeRequests()}
                /> : null }
                <PDFPreviewModal
                    show={!!this.state.pdfPreview}
                    onHide={() => this.setState({
                        ...this.state,
                        pdfPreview: undefined
                    })}
                    onDownload={() => this.downloadElectricityAgremeentFile(this.state.pdfPreview!)}
                    isDownloading={this.state.isDownloading}
                    onPrint={() => printJS({printable: this.state.pdfPreview?.objectUrl!, type: "pdf"})}
                    preview={this.state.pdfPreview}
                />
            </div>
        );
    }
}